<template>
	<div :class="themeClass" class="Allperfect-Box">
		<div class="allperfect-title">完善资料  </div>
		<div class="Allperfect-header1" v-loading='loading'>
			<el-form :model="formData" :rules="rules" ref="formData" label-width="165px" class="demo-ruleForm" v-loading='formLoading'>
				<div class="info-top">
					<el-form-item v-for="(item, index) in uploadRule" :key="index" :required="true" :label="item.rule.fRuleName">
						<div class="padding-10 border-F2F2F2">
							<UploadImgList :rules="item.rule" :uploadToken="uploadToken[index]" class="uploadimglist" :limit="1" :accept="limitTypeArr[index]"
							 v-model="imgListName[index]" uploadType="image" />
						</div>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;" v-if="limitTypeArr[index]||item.rule.fMaxSize">
							* 提示：
							<span>格式为{{item.rule.fFileType}}</span>
							<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
							<!-- <span v-if="item.rule.fMaxWidth">,宽度不能超过{{ item.rule.fMaxWidth }}px</span>
							<span v-if="item.rule.fMaxHeight">,高度不能超过{{ item.rule.fMaxHeight }}px</span> -->
						</div>
					</el-form-item>
					<el-form-item label="邮箱" prop="fEmail">
						<el-input v-model="formData.fEmail" placeholder="请输入邮箱地址"></el-input>
						<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;">
							* 提示：
							<span>邮箱可接收客户发票递送等信息</span>
						</div>
					</el-form-item>
				</div>
				<el-form-item>
					<el-button class="background-color-theme" @click="submitInform(1)" :disabled="!isEdit" :loading="buttonLoadingTJ">{{buttonText}}</el-button>
					<el-button class="background-color-theme" @click="submitInform(2)" :disabled="!isEdit" :loading="buttonLoadingZC">暂存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import Verification from '@/utils/Verification';
	import UploadImgList from '@/components/upload/UploadImgList';
	export default {
		components: {
			UploadImgList
		},
		data() {
			const fEmailchange = (rule, value, callback) => {
				if (!Verification.Verification.IsEmail(value)) {
					callback(new Error(Verification.Verification.EmailText));
				} else {
					callback();
				}
			};
			return {
				showEnterprise: false, //企业显示
				showPerson: false, //个人显示
				loading: false, //加载
				isUnderReview: false ,//是否审核中
				formData: {
					LegalName: "", //法人姓名
					LegalCard: "", //法人身份证
					fGovernmenAreaID: [], //选择行政区域ID
					fEmail: "", //邮箱地址
					bankAccount: "", //银行账号
					fBankTypeName: "", //开户行
					companyNumber: "", //公司电话
					fInvoiceClass: '', //发票类型
					fInvoiceClassID: '', //发票类型ID
					finviterCode: '' ,//邀请码
				},
				rules: {
					fRuleID: [{
						required: true,
						message: '请选择法人身份证',
						trigger: 'blur'
					}],
					fEmail: [{
						required: true,
						message: '请输入邮箱地址',
						trigger: 'blur'
					}, {
						required: false,
						validator: fEmailchange,
						trigger: "blur"
					}],
				},
				formLoading: false, //表单加载
				buttonLoadingZC : false,
				buttonLoadingTJ : false,
				buttonLoadingXZ : false,
				fInvoiceClassID: 2, //发票类型ID公司默认为2
				uploadRule: [], //上传图片规则
				uploadToken: [], //上传图片token
				limitTypeArr: [], //上传图片格式规则
				imgListName: [], //上传图片名
				userData: {},
				buttonText: '提交审核',
				isEdit: true, //是否可以编辑
				isEditOne: true, //编辑能否修改
				isCommit: false, //是否立即提交
				fBillID : '',
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {
			console.log(this.$route.query)
		},
		mounted() {
			// this.Enterprise()
			this.getAdjustBillTypeAnnexRule()
			// this.getData();
			this.getUserCurrentBill();
		},
		methods: {
			getData() {
				//获取用户完善资料提交单
				this.loading = true
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-current-customer-user-info').then(
					result => {
						// console.log(result, "result");
						if (result.obj.fCommerceTypeID == 2) {
							this.showEnterprise = true
						} else if (result.obj.fCommerceTypeID == 1) {
							this.showPerson = true
						} else {
							this.showEnterprise = true
							this.showPerson = true
						}
						this.loading = false;
					},
					rej => {
						this.loading = false;
					}
				);
			},
			//获取用户完善资料
			getUserCurrentBill() {
				this.formLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-perfect-bill')
					.then(
						res => {
							console.log('用户资料', res);
							if (res.obj) {
								this.userData = res.obj;
								if (this.userData.fCommerceTypeID == 2) {
									// if (this.getUserInfo.fCommerceTypeID == 2) {
									// 	this.buttonText = '变更资料';
									// 	this.isEditOne = false;
									// }
									this.formData.corporateName = this.userData.fCustomerName; //公司名
									this.formData.fBusiness = this.userData.fBusiness; //行业性质
									this.formData.fBusinessID = this.userData.fBusinessID + ''; //行业性质ID
									this.formData.fEmail = this.userData.fEmail + '';// 邮箱
									this.fBillID = this.userData.fBillID
									this.isUnderReview = this.userData.fApproveStatus == 1 ? true : false
									this.Enterprise()
									//行政区域数据处理
									if (this.userData.fFullIDPath != '' && this.userData.fFullIDPath != null && this.userData.fFullIDPath !=
										undefined) {
										let governmenAreaArr = this.userData.fFullIDPath.split("\\");
										//过滤数组中的空字符
										governmenAreaArr = governmenAreaArr.filter((s) => {
											return s && s.trim();
										});
										this.formData.fGovernmenAreaID = governmenAreaArr
										// console.log('this.formData.fGovernmenAreaID', this.formData.fGovernmenAreaID);
									} else {
										this.hasGovernmenArea = true;
									}
									this.formData.UnifiedCreditCode = this.userData.fIdentityCode; //统一信用代码
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型
									this.formData.fInvoiceClassID = this.userData.fInvoiceClassID + ''; //发票类型ID
									this.fInvoiceClassID = this.userData.fInvoiceClassID + '';
									this.formData.companyNumber = this.userData.fPhone; //公司电话
									this.formData.bankAccount = this.userData.fBankAccounts; //银行卡号
									this.formData.fBankTypeName = this.userData.fBankNames; //开户行
									//地址数据处理
									this.formData.address = this.userData.fAddress;
									this.formData.nameOfLegalPerson = this.userData.fLegalPerson; //法人姓名
									this.formData.IDCardNumber = this.userData.fLegalPersonCard; //法人身份证号
									this.formData.fRemark = this.userData.fRemark; //备注
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型名
									this.formData.finviterCode = this.userData.fInvitationCode; //邀请码
									// console.log(this.formData);
									this.$forceUpdate()
								} else {
									this.hasGovernmenArea = true;
								}
							} else {
								this.hasGovernmenArea = true;
							}
							this.getAdjustBillTypeAnnexRule();
						},
						error => {
							this.getAdjustBillTypeAnnexRule();
						}
					);
			},
			
			//获取fBillID
			getUserCurrentBill1() {
				this.formLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-perfect-bill')
					.then(
						res => {
							console.log('用户资料', res);
							if (res.obj) {
								if (res.obj.fCommerceTypeID == 2) {
									this.fBillID = res.obj.fBillID
									this.formLoading = false;
									this.$forceUpdate()
								} else {
									this.hasGovernmenArea = true;
									this.formLoading = false;
								}
							} else {
								this.hasGovernmenArea = true;
								this.formLoading = false;
							}
							// this.getAdjustBillTypeAnnexRule();
						},
						error => {
							// this.getAdjustBillTypeAnnexRule();
						}
					);
			},
			PersonalProfile() {
				this.$router.push({
					path: "/PersonalContent/PersonalProfile",
					query: ''
				});
			},
			Enterprise() {
				if (this.isUnderReview) {
					this.$router.push({
						path: "/UnderReview",
					});
				} else {
					this.$router.push({
						path: "/PersonalContent/Improve",
					});
				}
			},
			//表单验证
			submitInform(e) {
				if (e == 2) { //暂存
					let flag1 = true;
					if(this.formData.fEmail != ''){
						this.$refs.formData.validateField("fEmail", (message) => {
							if (message != '') {
								flag1 = false;
							}
						});
					}
					if (flag1) {
						this.buttonLoadingZC = true;
						this.buttonLoadingTJ = true
						this.Auditing(e)
					} else {
						content.scrollIntoView()
					}
				} else if (e == 1) {
					// console.log(123);
					let imageList = this.imgListName;
					for (let item of this.uploadRule) {
						// console.log(item.rule.fIsNecessary);
						if (item.rule.fIsNecessary == 1) {
							let flag = {}
							for (let item1 of imageList) {
								if (item1 && item1 != []) {
									flag = item1.find(findItem => findItem.ruleId == item.rule.fRuleID);
									// console.log(flag, 'flag');
									if (flag) {
										break;
									}
								}
							}
							flag = flag ? flag : {};
							if (!flag.ruleId) {
								this.$message({
									message: item.rule.fRuleName + '不能为空',
								});
								return
							}
						}
					}
					this.$refs.formData.validate((valid) => {
						if (valid) {
							this.buttonLoadingTJ = true;
							this.buttonLoadingZC = true;
							this.Auditing(e);
						} else {
							content.scrollIntoView()
							return false;
						}
					});
				} else {
					let flag1 = true;
					let flag2 = true;
					this.$refs.formData.validateField("fEmail", (message) => {
						if (message != '') {
							flag1 = false;
						}
					});
					if (flag1 && flag2) {
						this.buttonLoadingXZ = true;
						this.Auditing(2, true)
					} else {
						content.scrollIntoView()
					}
				}
			
			},
			//提交审核
			Auditing(type, flag) {
				if (type == 1) {
					//审核
					this.isCommit = true;
				} else {
					//保存
					this.isCommit = false;
				}
				let param = {
					files: [], //图片参数
					fCustomerName: '',
					fGovernmenAreaID: '',
					fIdentityCode: '',
					fBankNames: '',
					fBankAccounts: '',
					fInvoiceClassID: '',
					fPhone: "",
					fAddress: '',
					fAppTypeId: this.fAppTypeID,
					inviterCode: '', //邀请码
					isCommit: this.isCommit,
					fEmail : '',
					fBillID : ''
				};
				param.fCustomerName = this.formData.corporateName ? this.formData.corporateName : '';
				param.fGovernmenAreaID = this.formData.fGovernmenAreaID[this.formData.fGovernmenAreaID.length - 1];
				param.fGovernmenAreaID = param.fGovernmenAreaID ? param.fGovernmenAreaID : -1
				param.fIdentityCode = this.formData.UnifiedCreditCode ? this.formData.UnifiedCreditCode : '';
				param.fInvoiceClassID = this.formData.fInvoiceClassID ? this.formData.fInvoiceClassID : '';
				param.fAddress = this.formData.address;
				param.fBankNames = this.formData.fBankTypeName;
				param.fPhone = this.formData.companyNumber ? this.formData.companyNumber : '';
				param.fBankAccounts = this.formData.bankAccount;
				param.inviterCode = this.formData.finviterCode ? this.formData.finviterCode : '';
				param.fEmail = this.formData.fEmail ? this.formData.fEmail : '';
				param.fBillID = this.fBillID ? this.fBillID : '-1'
				for (let item of this.imgListName) {
					if(item == null){
						item = [];
					}
					for (let items of item) {
						if (items && items != []) {
							let imgData = {
								fFileName: items.fSourceFileName ? items.fSourceFileName : items.fPath,
								fPath: items.fPath,
								ruleId: items.ruleId
							}
							param.files.push(imgData)
						}
					}
				}
				// console.log(param);
				this.isEdit = false
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/perfect-customer-data', param)
					.then(res => {
						// console.log(JSON.stringify(res));
						// console.log(flag)
						// if (flag) {
							// console.log(flag)
							// this.getUserCurrentBill1()
							// this.GeneratePowerOfAttorney(param)
						// } else {
							this.$message({
								message: res.message,
								type: 'success'
							});
							let _this = this
							if (_this.isCommit) {
								//跳转到我的资料页面
								// setTimeout(() => {
								// 	_this.$router.replace({
								// 		path: "/PersonalContent/myStuff",
								// 	});
								// }, 2000);
								//跳转到审核中页面
								setTimeout(() => {
									_this.$router.replace({
										path: "/UnderReview",
										query: {
											type: 2
										}
									});
									this.isEdit = true
								}, 2000);
							}else{
								this.isEdit = true
								this.getUserCurrentBill1()
							}
							this.buttonLoadingZC = false;
							this.buttonLoadingTJ = false;
						// }
					}, error => {
						this.isEdit = true
						this.buttonLoadingZC = false;
						this.buttonLoadingTJ = false;
						this.buttonLoadingXZ = false;
					});
			},
			//获取验证规则
			getAdjustBillTypeAnnexRule() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-perfect-data-rule', {
						"id": this.fInvoiceClassID
					})
					.then(res => {
						// console.log('获取附件验证规则', res);
						this.uploadRule = res;
						let Token = {};
						let Type = [];
						res.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr.push(imitTypeStr)
						})
						let _this = this
						if (this.userData.files) {
							this.uploadRule.forEach((item, index) => {
								// console.log(this.userData);
								_this.userData.files.forEach((item1, index1) => {
									if (item.rule.fAnnexTypeID == item1.fAnnexTypeID) {
										_this.imgListName[index] = [];
										_this.imgListName[index].push({
											fPath: item1.fPath,
											ruleId: item.rule.fRuleID
										})
									}
								});
							});
						}
						this.formLoading = false;
					}, error => {
						this.formLoading = false;
					});
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}
	.Allperfect-Box {
		width: 917px;
		height: 100%;
		background-color: #FFFFFF;
		margin-top: 13px;
	}

	.allperfect-title {
		font-size: 20px;
		font-weight: bold;
		padding: 11px 0px 0px 13px;
	}

	.Allperfect-header {
		width: 360px;
		height: 109px;
		border-radius: 5px;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
		border: 0px solid #639af1;
		background-color: #fff;
		position: relative;
		float: left;
		margin-right: 26px;
		margin-top: 40px;
		user-select: none;
	}

	.Allperfect-header1 {
		margin-left: 70px;
	}

	.allperfect-one {
		display: flex;
		font-weight: bold;
		padding: 28px 0px 8px 15px;
		font-size: 18px;
	}

	.allperfect-two {
		font-weight: 400;
		color: #999999;
		padding-left: 15px;
	}

	.Allperfect-header:hover {
		background-color: #f8f9fe;

		.allperfect-three {
			animation: mymove 0.3s infinite;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		.allperfect-three1 {
			animation: mymove 0.3s infinite;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}

	@keyframes mymove {
		from {
			right: 10px;
			bottom: 1px;
		}

		to {
			right: 17px;
			bottom: 13px;
		}
	}

	.allperfect-three {
		position: absolute;
		right: 10px;
		bottom: 7px;
		width: 120px;
	}

	.allperfect-three1 {
		position: absolute;
		right: 10px;
		bottom: 1px;
		width: 122px;
	}

	.card-box {
		height: 100%;
	}

	.card-box-button {
		width: 103px;
		height: 24px;
		border-radius: 2px;

		@include themify($themes) {
			color: themed('themes_color');
			border: 1px solid themed('themes_color');
		}
	}
</style>
